import React from "react"
import { Link } from "gatsby"
import "./terms.css"

const css = (active, type) =>
  `terms-header-item ${active == type ? "terms-active" : ""}`
const Terms = ({ active, children }) => (
  <div>
    <div className="terms-header">
      <Link to="/terms_models" style={{ textDecoration: "none" }}>
        <div className={css(active, "models")}>MODELS</div>
      </Link>
      <Link to="/terms_brands" style={{ textDecoration: "none" }}>
        <div className={css(active, "brands")}>BRANDS</div>
      </Link>
    </div>
    <div className="terms-content">{children}</div>
    <br/>
  </div>
)

export default Terms
