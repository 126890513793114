import React from "react"
import Terms from "../components/terms"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./privacy_policy.css"

const TermsBrands = () => {
  return (
    <Layout page="terms">
      <SEO title="Terms of Use - Brands" description="Our terms of use for the brands we work with." />
      <div className="flex-grid">
        <div className="col" />
        <div style={{ flex: "0 1 994px", marginTop: "50px" }}>
          <Terms active="brands">
            <div
              className="privacy-policy-title"
              style={{ marginTop: "-20px" }}
            >
              Terms of Use - Brands
            </div>
            <div className="privacy-policy-text">
              <p>Neon Coat, LLC (&ldquo;<strong>Neon Coat</strong>&rdquo;&nbsp;or&nbsp;&ldquo;<strong>we</strong>&rdquo;&nbsp;or&nbsp;&ldquo;<strong>us</strong>&rdquo;&nbsp;or&nbsp;&ldquo;<strong>our</strong>&rdquo;) provides a platform for directly connecting businesses and signed models (the&nbsp;&ldquo;<strong>Services</strong>&rdquo;).</p>
              <p>These Terms of Service (&ldquo;<strong>Terms</strong>&rdquo;) apply to your access and use of the Neon Coat mobile application (the&nbsp;&ldquo;<strong>App</strong>&rdquo;), our website located at <a href="https://www.neoncoat.com">https://www.neoncoat.com</a> (the&nbsp;&ldquo;<strong>Site</strong>&rdquo;), our proprietary platform that powers the Services and other online products and services of Neon Coat (collectively, with the App and the Site, the&nbsp;&ldquo;<strong>Neon Coat Services</strong>&rdquo;). These Terms will govern any upgrades to the Neon Coat Services, unless the upgrade is accompanied by a separate license, in which case the terms of that license will govern.</p>
              <p><strong>The Neon Coat Services</strong><br /> Through the Neon Coat Services, you can be connected with signed models (each a&nbsp;<strong>&ldquo;Neon Coat Model&rdquo;</strong>) to engage for a variety of promotional purposes, which may include (without limitation) modeling or other professional gigs, sampling your products and services, and attending your venues or events (each, an&nbsp;&ldquo;<strong>Engagements</strong>&rdquo;). When you submit a request to us for a model or models for a particular Engagement (each a&nbsp;&ldquo;<strong>Request</strong>&rdquo;), you must provide related details as requested by Neon Coat, including (without limitation) the incentives or other compensation offered to the models for such Engagement (the&nbsp;&ldquo;<strong>Offer</strong>&rdquo;).</p>
              <p>Submission of a Request does not obligate Neon Coat in any way, and Neon Coat has the right to accept or reject a Request in its sole discretion. If Neon Coat accepts a Request, it will broadcast the Offer to the Neon Coat Models in whatever manner and format that Neon Coat, in its sole discretion, deems appropriate. Neon Coat further reserves the right, but has no obligation, to promote the Offer through its affiliates and third-party business partners.</p>
              <p>When redeeming an Offer, Neon Coat Models will receive (as applicable) an electronic ticket via the App that evidences redemption of the Offer and contains the terms and conditions of such Offer (an &ldquo;<strong>E-Ticket</strong>&rdquo;).<br /> Neon Coat Models may then redeem the Offer by presenting their E-Tickets to you at the Engagement (as applicable) through their mobile devices (or via any other method that Neon Coat has adopted for E-Ticket redemption), and having you check them in manually via the App (or consenting to their checking themselves in via the App).</p>
              <p>You agree to comply with your obligations as specified in each Offer, including but not limited to honoring all of your Offers (unless you reasonably believe that an attempt is being made to redeem an E-Ticket in an unauthorised or illegal manner, in which case you shall immediately contact Neon Coat and explain the circumstances) and complying with the terms of your Offers as stated without modification, and without imposing any restrictions or additional charges or penalties that are not expressly stated in the Offer.</p>
              <p>You may subcontract or delegate to a third party portions of your performances required by these Terms with respect to a particular Offer (each, a&nbsp;&ldquo;<strong>Third Party Merchant</strong>&rdquo;). If you subcontract or delegate to Third Party Merchants with respect to fulfilling an Offer, you will remain solely responsible and liable for (a) all your obligations, including financial obligations, under these Terms and (b) all acts and omissions of your Third Party Merchants. You shall require your Third Party Merchants to comply with all applicable restrictions and obligations imposed on you under these Terms, and you will be solely responsible for your financial and contractual relationship with your Third Party Merchants.</p>
              <p>Should you encounter any inappropriate conduct or behavior by a Neon Coat Model, please contact us at <a href="mailto:info@neoncoat.com"> info@neoncoat.com</a>.</p>
              <p><strong>Conditions of Use</strong><br /> You may not use the Neon Coat Services unless (i) you are at least 18 years old; (ii) you represent a legitimate business; and (iii) you agree to these Terms, which may be modified at any time. If you disagree with any such modifications, your only remedy is to immediately discontinue use of the Neon Coat Services.</p>
              <p><strong>Acceptance</strong><br /> BY DOWNLOADING THE APP OR OTHERWISE ACCESSING OR USING THE NEON COAT SERVICES, YOU AGREE TO BE BOUND BY ALL OF THE TERMS HEREIN, INCLUDING YOUR AGREEMENT TO SUBMIT ANY DISPUTES TO BINDING ARBITRATION. Please read all of the terms before you use the Neon Coat Services. If a term does not make sense to you, please let us know.</p>
              <p><strong>Modifications</strong><br /> We reserve the right to change these Terms from time to time. If we make changes, we will notify you by revising the date at the top of the Terms (and, in some cases, providing you with additional notice such as adding a statement to our homepage or sending you an email notification). All users of Neon Coat Services should review the Terms periodically to ensure they stay informed of any updates. Changes to these Terms will be effective when posted (unless you are notified otherwise), and your continued use of Neon Coat Services after the effective date of any such revisions will signify your acceptance of those changes.</p>
              <p><strong>Privacy Policy</strong><br /> For information about how we collect, use and share information about users of the Neon Coat Services, please see our Privacy Policy at <a href="https://www.neoncoat.com/privacy-policy">https://www.neoncoat.com/privacy-policy</a>.</p>
              <p><strong>Accounts</strong><br /> You must create an account to use the Neon Coat Services, and if you are creating an account for a business, you represent and warrant that you have the authority to create and use the account on behalf of that business. We may require such account to be linked to one or more of your social media accounts (e.g., Instagram). You will need to provide us with your contact information, as well as certain billing information, which may include credit card information. You are responsible for all activity performed through your account, and accept all risk that someone may access your account without your permission. As such, you should keep your log-in credentials secure.</p>
              <p>By creating an account with Neon Coat, you represent and warrant to us that (i) you are at least 18 years old; (ii) all information that you provide in connection with your account is at all times truthful, accurate, current and complete; (iii) your use of the Neon Coat Services will not violate any applicable law, or any contractual obligations you have to any third parties; and (iv) you will comply with these Terms. Neon Coat reserves the right to deny or terminate any account, and to remove any Offers, at our discretion, for any reason. You promise to update the information you have provided to Neon Coat in the event of any changes to your account information.</p>
              <p><strong>Payment</strong><br /> You and Neon Coat will separately agree to a Subscription or Services Agreement that governs payment for the Neon Coat Services. All such payments are non-refundable.</p>
              <p><strong>Your Engagements</strong><br /> Any unlawful, dangerous, or inappropriate behavior may result in our terminating your account and access to the Neon Coat Services, in our sole discretion.</p>
              <p>You represent and warrant that all information you provide for us, including without limitation your name, birthdate and payment information, is accurate and will be updated promptly as necessary. You agree and acknowledge that Neon Coat reserves the right to perform background checks on you in its sole discretion.</p>
              <p>Neon Coat does not have any control over the environments used for your Engagements, and does not evaluate or investigate the physical or environmental elements related to them. You agree that you will provide a safe environment for Neon Coat Models in connection with your Engagements, and you will not knowingly or negligently create or include any physical hazards at them. You will not ask or encourage any Neon Coat Model to perform any action that is illegal, unethical, immoral, abusive, offensive or hateful. You retain all responsibility and liability for any injury, illness, death, loss of property or any other damage that may be suffered by a Neon Coat Model in connection with an Engagement. Under your reasonable and responsible care and authority, and subject to prevailing local laws, alcoholic beverages may be served or sold at your Engagements. Neon Coat has no ability or authority to monitor what or how much alcohol or other substance is served to, sold to, or consumed by any Neon Coat Model or other attendee at your Engagement. You assume all responsibility for confirming that any Neon Coat Model is of the applicable legal drinking age.</p>
              <p><strong>No Agency</strong><br /> Neon Coat does not intend to appoint you or any of your employees or agents as a Neon Coat employee or legal agent, or to form any kind of legal partnership or joint venture with you. You are not authorized to make any commitments on behalf of Neon Coat, and Neon Coat will not make commitments on your behalf, except as contemplated by the Neon Coat Services or as expressly stated in these Terms.</p>
              <p><strong>RELEASE OF LIABILITY</strong><br /> NEON COAT HAS NO LIABILITY TO YOU, ANY NEON COAT MODEL, OR ANY ATTENDEES AT AN ENGAGEMENT FOR ANY LOSS, INJURY, DISABILITY, DEATH, COST, EXPENSE, DAMAGE (WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, CONSEQUENTIAL AND/OR OTHERWISE) OR OTHER CLAIM OF ANY KIND WHATSOEVER (COLLECTIVELY, &ldquo;CLAIMS&rdquo;) DIRECTLY OR INDIRECTLY ARISING OR RESULTING FROM, OR OTHERWISE RELATING TO, YOUR ENGAGEMENTS, INCLUDING THE ACTIONS OR INACTIONS BY ANY NEON COAT MODEL IN CONNECTION WITH SUCH ENGAGEMENTS.&nbsp;</p>
              <p>YOUR USE OF THE NEON COAT SERVICES IS VOLUNTARY, AND YOU FULLY ACCEPT ANY AND ALL ASSOCIATED RISK WITH SUCH USE. YOU FURTHER RELEASE NEON COAT, ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS, AGENTS AND REPRESENTATIVES, AND THEIR RESPECTIVE SUCCESSORS, HEIRS AND ASSIGNS (COLLECTIVELY, THE &ldquo;RELEASEES&rdquo;), FROM AND AGAINST ANY CLAIMS DIRECTLY OR INDIRECTLY ARISING OR RESULTING FROM, OR OTHERWISE RELATING TO, (I) YOUR USE OF THE NEON COAT SERVICES AND (II) YOUR ENGAGEMENTS AND OFFERS.&nbsp;</p>
              <p>You acknowledge that you (and the owner of the property at which your Engagement is held, if applicable) retain any and all liability that may attach to you under the applicable laws of your jurisdiction pertaining to the sale or service of alcoholic beverages to any persons on the premises at which your Engagement is being held, including for any acts, events, or circumstances that result from the sale or service of alcohol to any attendee, including any Neon Coat Model.</p>
              <p><strong>Right to Use the Neon Coat Services</strong><br /> Provided that you fully comply with these Terms, Neon Coat grants you a limited, nonexclusive, non-transferable and revocable license to access and use the Neon Coat Services for your own personal use (other than as expressly authorized herein).&nbsp;</p>
              <p>Except as expressly authorized by these Terms, you may not (a) modify, disclose, alter, translate or create derivative works of the Neon Coat Services, (b) license, sublicense, resell, distribute, lease, rent, lend, transfer, assign or otherwise dispose of the Neon Coat Services, (c) disassemble, decompile or reverse engineer any of the software components of the Neon Coat Services, (d) copy, frame or mirror any part of the Neon Coat Services, (e) interfere with or disrupt the integrity or performance of the Neon Coat Services, or (f) attempt to gain unauthorized access to the Neon Coat Services or its related systems or networks.</p>
              <p><strong>Prohibited Use of the Neon Coat Services</strong><br /> You may not assert, post or otherwise make available on or through the Neon Coat Services any of the following:</p>
              <ul>
                <li>Content, including any that relates to an Engagement, that is untruthful or inaccurate;</li>
                <li>Content that may result in harm, injury or damage to a person or product;</li>
                <li>Content that is libelous, defamatory, abusive, offensive or hateful;</li>
                <li>Content that is obscene, pornographic, indecent or sexually explicit, depicts graphic, excessive or gratuitous violence;</li>
                <li>Content that is illegal, harmful or offensive or that would encourage, solicit, foster, glorify or provide instructions for any criminal or civil offense;</li>
                <li>Content that may infringe, misappropriate or violate any intellectual property rights,rights of privacy, rights of publicity or any other rights of others;</li>
                <li>Viruses, corrupted data or other harmful, disruptive or destructive files or code;</li>
                <li>Content that, in Neon Coat&rsquo;s reasonable judgment, is objectionable, may restrict or inhibit another from enjoying the Services or may expose Neon Coat or users of the Neon Coat Services to harm or liability of any type; and/or</li>
                <li>Content that you are contractually or legally required to keep confidential.</li>
              </ul>
              <p>Also, you may not do any of the following in connection with the Neon Coat Services or other users of the Neon Coat Services:</p>
              <ul>
                <li>Use the Neon Coat Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the Neon Coat Services or that could damage, disable, overburden or impair the functioning of the Neon Coat Services;</li>
                <li>Collect any personal information about Neon Coat Models or other users;</li>
                <li>Intimidate, threaten, stalk, bully or otherwise harass Neon Coat Models or other users;</li>
                <li>Post spam or commercial messages through the Neon Coat Services;</li>
                <li>Create an account if you are not over 18 years of age;</li>
                <li>Use the Neon Coat Services as a platform for dating or matchmaking services, solicitation, escort services, or any other purpose except for the Services as described at the beginning of these Terms;</li>
                <li>Use the Neon Coat Services for any illegal or unauthorized purpose (including, without limitation, any use that would violate the FTC&rsquo;s Endorsement Guides) or to engage in, encourage or promote any activity that is unlawful or that violates these Terms; or</li>
                <li>Circumvent or attempt to circumvent any filtering, security measures, rate limits or other features designed to protect the Neon Coat Services, its users, or third parties.</li>
              </ul>
              <p><strong>Disputes between You and a Neon Coat Model</strong><br /> Your use of the Neon Coat Services is at your own risk. Neon Coat is not responsible or liable for the conduct of, or your interactions with, any Neon Coat Models or for any related damage or harm. The Neon Coat Services are merely a neutral means of connecting you with Neon Coat Models. Any dispute that you may have regarding the conduct of any Neon Coat Model is between you and the applicable Neon Coat Model. Neon Coat makes no representations or warranties of any kind in connection with Neon Coat Models (other than as expressly stated).</p>
              <p><strong>Neon Coat&rsquo;s Rights</strong><br /> As between you and Neon Coat, all information, materials and content of the Neon Coat Services, including but not limited to text, graphics, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, designs, typefaces, source and object code, format, queries, and algorithms, is owned by Neon Coat or is used with permission. Subject to the terms of our Privacy Policy, when you create, share, link to, or otherwise make available any information, including without limitation your name, contact information, uploaded images, ratings or other feedback about a Neon Coat Model, or other content (&ldquo;<strong>User Content</strong>&rdquo;), you grant us a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such User Content throughout the world in any manner or media, on or off the Neon Coat Services. Neon Coat reserves all rights not expressly set forth in these Terms. You hereby irrevocably waive in favor of Neon Coat any and all moral rights that you may possess in or to anything that you submit or post on or through the Neon Coat Services.</p>
              <p><strong>Feedback</strong><br /> Any suggestions, comments or other feedback you give us about the Neon Coat Services (&ldquo;<strong>Feedback</strong>&rdquo;) will constitute our confidential information. You acknowledge and agree that we are free to use, disclose, reproduce, license, distribute and exploit Feedback in our sole discretion, without any obligation or compensation to you.</p>
              <p><strong>Disclaimers</strong><br /> EXCEPT AS REQUIRED OTHERWISE OF NEON COAT BY APPLICABLE LAW, THE NEON COAT SERVICES AND ANY OTHER SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES (INCLUDING ANY USER CONTENT) ARE PROVIDED TO YOU ON AN &ldquo;AS IS,&rdquo; &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM AND EXCLUDE ANY AND ALL OTHER WARRANTIES, CONDITIONS, AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN), AND ALL OTHER TERMS THAT MAY BE IMPLIED INTO THESE TERMS BY LAW, WITH RESPECT TO THE NEON COAT SERVICES AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE NEON COAT SERVICES (INCLUDING ANY USER CONTENT).</p>
              <p><strong>Limitation of Liability</strong><br /> TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL NEON COAT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHATSOEVER INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROPERTY, PHYSICAL LOSSES, DEATH OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO THESE TERMS, THE NEON COAT SERVICES (INCLUDING THE APP AND ANY USER CONTENT), ENGAGEMENTS, ANY NEON COAT MODELS&rsquo; CONDUCT, AND/OR THIRD PARTY SERVICES OR MATERIALS, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY) AND EVEN IF NEON COAT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, THIS LIMITATION MAY NOT APPLY TO YOU.)</p>
              <p>FOR ANY LOSS OR DAMAGE THAT IS NOT EXCLUDED UNDER THESE TERMS, THE TOTAL LIABILITY OF NEON COAT AND ITS DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES AND INVESTORS TO YOU WILL NOT EXCEED FIFTY DOLLARS (USD $50.00).</p>
              <p><strong>Waiver</strong><br /> Except as expressly provided in these Terms, Neon Coat and the Neon Coat Models do not intend to accept liability with respect to your use of the Neon Coat Services. AS SUCH, YOU WAIVE ANY AND ALL RIGHTS YOU HAVE TO SUE OR MAKE CLAIMS AGAINST NEON COAT AND ITS DIRECTORS, OFFICERS, OWNERS, MANAGERS, AGENTS, OR EMPLOYEES (THE &ldquo;NEON COAT PARTIES&rdquo;) FOR ANY CLAIMS ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE NEON COAT SERVICES INCLUDING, WITHOUT LIMITATION, ANY ACTIONS OR INACTION OF THE NEON COAT MODEL(S). WITH RESPECT TO NEON COAT MODELS, YOUR WAIVER DOES NOT EXTEND TO ANY DAMAGES OR LOSSES RESULTING FROM ANY PARTY&rsquo;S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>
              <p>IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE &sect;1542, WHICH STATES: &ldquo;A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.&rdquo;</p>
              <p><strong>Indemnification</strong><br /> YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THE RELEASEES FROM AND AGAINST ANY AND ALL DEMANDS, SUITS, PROCEEDINGS (AT LAW OR IN EQUITY), ACTIONS, JUDGMENTS AND OTHER CLAIMS OF ANY KIND WHATSOEVER ARISING OR RESULTING FROM, OR OTHERWISE RELATING TO, (I) YOUR USE OF THE NEON COAT SERVICES (INCLUDING ANY USE OF YOUR NEON COAT ACCOUNT, WHETHER OR NOT AUTHORIZED BY YOU), (II) YOUR VIOLATION OF THESE TERMS, (III) YOUR ENGAGEMENTS AND OFFERS (INCLUDING, WITHOUT LIMITATION, AND CLAIMS ALLEGED BY NEON COAT MODELS), AND (IV) ANY RESULTING OR RELATED ACTS, EVENTS OR CIRCUMSTANCES.</p>
              <p><strong>Third Party Software and Links</strong><br /> The App and the Site may contain links to third-party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. Neon Coat does not endorse any such sites or the information, materials, products or services contained on or accessible through such sites. Neon Coat has no control over, and assumes no responsibility for, the content, privacy policies or practices of any third-party websites or services. If you access a third-party website from the App or the Site, you do so at your own risk, and you understand that these Terms do not apply to your use of such sites. You expressly relieve Neon Coat from any and all liability arising from your use of any third-party website or services or third party-owned content. Additionally, your dealings with or participation in promotions of advertisers found on the App or the Site, including payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. You agree that Neon Coat will not be responsible for any loss or damage of any sort relating to your dealings with such advertisers. Neon Coat encourages you to be aware of when you leave the App or the Site, and to read the terms and conditions and privacy policy of any third-party website or service that you visit.</p>
              <p><strong>Changes to the Services</strong><br /> Neon Coat reserves the right in our sole discretion to review, improve, change or discontinue, temporarily or permanently, the Neon Coat Services and/or any features, information, materials or content on the Neon Coat Services with or without providing notice to you. Neon Coat will not be liable to you or any third party for any changes or discontinuance of the Neon Coat Services or any part of the Neon Coat Services.</p>
              <p><strong>Consent to Electronic Communications</strong><br /> By using the Neon Coat Services, you agree that we may communicate with you electronically regarding your use of the Neon Coat Services and that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including that the communications be in writing. To withdraw your consent from receiving electronic notice, please notify us at <a href="mailto:info@neoncoat.com"> info@neoncoat.com</a>.</p>
              <p><strong>Suspension and Termination</strong><br /> Neon Coat may suspend or terminate your rights to access or use the Neon Coat Services (including the App) for any reason or for no reason at all and with or without notice at Neon Coat&rsquo;s sole discretion. Suspension or termination may include restricting access to and use of the App. All of the terms of these Terms (excluding the license grant) will survive any termination or suspension. Termination of access to the Neon Coat Services will not release either party from any obligations incurred prior to such termination, and Neon Coat may retain and continue to use for its own internal purposes any information previously provided by you.</p>
              <p><strong>Governing Law; Arbitration</strong><br /> PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY. THEY REQUIRE YOU TO ARBITRATE DISPUTES WITH NEON COAT, AND LIMIT THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM NEON COAT.</p>
              <p>These Terms will be governed by and construed in accordance with the laws of the State of New York and the United States of America, without resort to any conflict of law provisions. In the event of any controversy or claim arising out of or relating in any way to these Terms or the Neon Coat Services, you and Neon Coat agree to consult and negotiate with each other and, recognizing your mutual interests, try to reach a solution satisfactory to both parties. If we do not reach settlement within a period of 60 days, then either of us may, by notice to the other demand mediation under the mediation rules of the JAMS/Endispute (<strong>&ldquo;JAMS&rdquo;</strong>) in New York, New York. Both you and Neon Coat both give up the right to litigate disputes and may not proceed to arbitration without first trying mediation, but you and Neon Coat are not required to arbitrate any dispute in which either party seeks equitable and other relief from the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets or patents. Whether the dispute is heard in arbitration or in court, you and Neon Coat will not commence against the other a class action, class arbitration or other representative action or proceeding.</p>
              <p>If settlement is not reached within 60 days after service of a written demand for mediation, any unresolved controversy or claim will be resolved by arbitration in accordance with the rules of JAMS before a single arbitrator in New York, New York. The language of all proceedings and filings will be English. The arbitrator will render a written opinion including findings of fact and law and the award and/or determination of the arbitrator will be binding on the parties, and their respective administrators and assigns, and will not be subject to appeal. Judgment may be entered upon the award of the arbitrator in any court of competent jurisdiction. The expenses of the arbitration will be shared equally by the parties unless the arbitration determines that the expenses will be otherwise assessed and the prevailing party may be awarded its attorneys&rsquo; fees and expenses by the arbitrator. It is the intent of the parties that, barring extraordinary circumstances, arbitration proceedings will be concluded within 90 days from the date the arbitrator is appointed. The arbitrator may extend this time limit only if failure to do so would unduly prejudice the rights of the parties. Failure to adhere to this time limit will not constitute a basis for challenging the award. Consistent with the expedited nature of arbitration, pre-hearing information exchange will be limited to the reasonable production of relevant, non-privileged documents, carried out expeditiously.</p>
              <p>For any disputes that are not handled by arbitration, you agree that any action at law or in equity arising out of or relating to these Terms will be filed only in the state and federal courts located in New York, New York and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of these Terms or the use of the Neon Coat Services.</p>
              <p><strong>No Circumvention</strong><br /> Any attempt to try to circumvent Neon Coat (e.g., by contacting Neon Coat Models directly for the express purpose of circumventing the Neon Coat Services) is prohibited and may result in the termination of your account and/or access to the Neon Coat Services in Neon Coat&rsquo;s sole discretion.</p>
              <p><strong>General</strong><br /> Enforcement of these Terms is solely at Neon Coat&rsquo;s discretion. Failure to enforce any part of these Terms in some instances does not constitute a waiver of our right to enforce the same or other part of these Terms in other instances. If any provision of these Terms is or becomes unlawful, void or otherwise unenforceable (including the warranty disclaimers and liability limitations above), then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remaining provisions of these Terms will continue in full force and effect. The section headings of these Terms are for reference purposes only and will not affect the meaning or interpretation of these Terms.</p>
              <p>These Terms (and the other policies and terms referred to above) make up the entire agreement between you and us regarding the Neon Coat Services, and they supersede any prior agreements that may have been made.</p>
              <p><strong>Contact Us</strong><br /> If you have any questions about these Terms, please email <a href="mailto:info@neoncoat.com"> info@neoncoat.com</a>.</p>
            </div>
          </Terms>
        </div>
        <div className="col" />
      </div>
    </Layout>
  )
}

export default TermsBrands
